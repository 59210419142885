import React from 'react'

import RectangleCard from '../../UI/RectangleCard/ReactangleCard'
import style from './RoadMap.module.scss'
export default function RoadMap() {
	const cards = [
		{
			color: 'yellow',
			title: 'PHASE 1',
			text: 'coin launch, dex listing, audience recruitment',
			animationClass: 'fade-in-left',
		},
		{
			color: 'light-green',
			title: 'PHASE 2',
			text: 'Pump buly again',
			animationClass: 'fade-in-right',
		},
		{
			color: 'light-violet',
			title: 'PHASE 3',
			text: 'buly to the moon',
			animationClass: 'fade-in-bottom',
		},
	]
	return (
		<div className={style.road_map}>
			<div className={style.cards}>
				<h1 className={style.title}>roadmap</h1>
				<h3 className={style.subtitle}>find out about BULLY's future plans</h3>
				{cards.map((card, index) => (
					<RectangleCard
						key={index}
						color={card.color}
						title={card.title}
						text={card.text}
						animationClass={card.animationClass}
					/>
				))}
			</div>
		</div>
	)
}
