import React from 'react'
import Header from '../Header/Header'
import Main from '../screens/Main/Main'
import Carousel from '../UI/Carousel/Carousel'
import style from './Layout.module.scss'
import AboutUs from "../screens/AboutUs/AboutUs";
import HowToBuy from "../screens/HowToBuy/HowToBuy";
import Tokenomics from "../screens/Tokenomics/Tokenomics";
import RoadMap from "../screens/RoadMap/RoadMap";
import Footer from "../Footer/Footer";

export default function Layout() {
	return (
		<div className={style.layout}>
			<Carousel phrase={'WELCOME TO BULLY BULL'} position={'top'} />
			<Header />
			<Main />
			 <AboutUs />
			 <HowToBuy />
			 <Tokenomics />
			 <RoadMap />
			 <Footer />
			 <Carousel phrase={'WELCOME TO BULLY BULL'} position={'bottom'} />
		</div>
	)
}
