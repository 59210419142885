import React from 'react'
import Button from '../UI/Button/Button'
import style from './Header.module.scss'
import Logo from './Logo'
export default function Header() {
	const pushHref = () =>
		(window.location.href =
			'https://birdeye.so/token/HEMuxZtYy86LcgaRWULnWPf4KpJ1B9r9R9CANR41pump?chain=solana')
	return (
		<div className={style.header}>
			<div className={style.header_content}>
				<Logo />
				<Button color='green' text='Buy $Bully' callback={pushHref} />
			</div>
		</div>
	)
}
