import React, { useEffect, useRef } from 'react'
import './Carousel.scss'

export default function Carousel({ phrase, position }) {
	const containerRef = useRef(null)

	// Create the repeated phrase with a dynamic space
	const repeatedPhrase = Array.from(
		{ length: 40 },
		() => `${phrase}  \u00A0\u00A0\u00A0\u00A0`
	).join('') // Adds non-breaking spaces

	useEffect(() => {
		const container = containerRef.current
		let animation

		const startAnimation = () => {
			const { offsetWidth } = container
			let start = 0
			const step = () => {
				start -= 0.5 // Adjusted speed for smoother scrolling
				container.style.transform = `translateX(${start}px)`

				if (Math.abs(start) >= offsetWidth / 2) {
					start = 0
				}

				animation = requestAnimationFrame(step)
			}

			animation = requestAnimationFrame(step)
		}

		startAnimation()

		// Clean up animation on component unmount
		return () => {
			cancelAnimationFrame(animation)
		}
	}, [])

	return (
		<div className={`carousel-container ${position}`}>
			<div className='carousel-content' ref={containerRef}>
				{repeatedPhrase}
			</div>
		</div>
	)
}
