import React from 'react'
import bully from '../../../assets/pictures/bully-big.svg'
import '../../../assets/style/animations.scss'
import Contacts from '../../UI/Contacts/Contacts'
import Contract from './Contract/Contract'
import style from './Main.module.scss'
export default function Main() {
	return (
		<div className={style.main}>
			<div className={style.content}>
				<div className={`${style.info} fade-in-left`}>
					<h1>$Buly</h1>
					<h3>
						$BULY is Memcoin on solanA. The best mascot on the current market!
					</h3>
					<Contract CA={'HEMuxZtYy86LcgaRWULnWPf4KpJ1B9r9R9CANR41pump'} />

					<Contacts />
				</div>
				<img
					className={`${style.big_bully} fade-in-right`}
					src={bully}
					alt='Bully Mascot'
				/>
			</div>
		</div>
	)
}
