import React from 'react'
import style from './Button.module.scss'

/**
 *
 * @param {Object} props
 * @param {string} props.text - Required
 * @param {string} props.color - Required ('violet', 'yellow', 'green')
 * @returns {JSX.Element}
 */
const Button = ({ text, color, callback }) => {
	const buttonClass = `${style.button} ${style[color]}`

	return (
		<button onClick={() => callback()} className={buttonClass}>
			<p>{text}</p>
		</button>
	)
}

export default Button
