import React, { useEffect, useRef, useState } from 'react'
import about_picture from '../../../assets/pictures/about_picture.svg'
import style from './AbousUs.module.scss'
export default function AboutUs() {
	const imgRef = useRef(null)
	const [isIntersecting, setIsIntersecting] = useState(false)
	const [scale, setScale] = useState(0.1)

	useEffect(() => {
		const observer = new IntersectionObserver(
			entries => {
				const entry = entries[0]
				setIsIntersecting(entry.isIntersecting)
			},
			{ threshold: 0.5 }
		)

		if (imgRef.current) {
			observer.observe(imgRef.current)
		}

		return () => {
			if (imgRef.current) {
				observer.unobserve(imgRef.current)
			}
		}
	}, [])

	useEffect(() => {
		const handleScroll = () => {
			if (isIntersecting) {
				const scrollY = window.scrollY
				const imageOffsetTop = imgRef.current.offsetTop
				const viewportHeight = window.innerHeight

				let newScale =
					0.6 +
					((scrollY + viewportHeight - imageOffsetTop) / viewportHeight) * 0.4

				newScale = Math.min(newScale, 1)
				setScale(newScale)
			}
		}

		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [isIntersecting])

	return (
		<div className={style.about_us}>
			<h1>About Us</h1>
			<h3>
				$BULY is Memcoin on solanA. The best mascot on the current market! Buy
				$BULY and become a real bull of this market.
			</h3>
			<img
				className={style.about_picture}
				ref={imgRef}
				src={about_picture}
				alt=''
				style={{
					transform: `scale(${scale})`,
					transition: 'transform 0.1s ease-out',
				}}
			/>
		</div>
	)
}
