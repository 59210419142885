import React, { useEffect, useRef, useState } from 'react'
import diagram from '../../../assets/pictures/diagram.svg'
import Stat from './Stat'
import style from './Tokenomics.module.scss'
export default function Tokenomics() {
	const infoRef = useRef(null)
	const [isVisible, setIsVisible] = useState(false)
	useEffect(() => {
		const observer = new IntersectionObserver(
			entries => {
				const entry = entries[0]
				if (entry.isIntersecting) {
					setIsVisible(true)
					observer.unobserve(entry.target)
				}
			},
			{ threshold: 0.5 }
		)

		if (infoRef.current) {
			observer.observe(infoRef.current)
		}

		return () => {
			if (infoRef.current) {
				observer.unobserve(infoRef.current)
			}
		}
	}, [])
	return (
		<div className={style.tokenomics}>
			<div className={style.content}>
				<div className={style.info}>
					<h1 ref={infoRef} className={isVisible ? 'fade-in-left' : ''}>
						Tokenomics
					</h1>
					<h3 ref={infoRef} className={isVisible ? 'fade-in-left' : ''}>
						All about tokenomics buly
					</h3>
					<Stat color={'light-violet'} text={'100% Burning'} />
					<Stat color={'light-green'} text={'0% Tax'} />
				</div>
				<img
					ref={infoRef}
					className={`${style.diagram} ${isVisible ? 'fade-in-right' : ''}`}
					src={diagram}
					alt='Diagram'
				/>
			</div>
		</div>
	)
}
