import React from 'react'
import Link from '../Link/Link'
import style from './Contacts.module.scss'
export default function Contacts() {
	const links = [
		{
			iconSrc: '/Contacts/link1.svg',
			link: 'https://x.com/bullybull2025',
		},
		{
			iconSrc: '/Contacts/link2.svg',
			link: 'https://t.me/BullyBull_SOL',
		},
		{
			iconSrc: '/Contacts/link5.svg',
			link: 'https://dexscreener.com/solana/8fyshod6nwmahtifddnv75v9iguornm22rpxmpz6xfmz',
		},
		{
			iconSrc: '/Contacts/link6.svg',
			link: 'https://www.geckoterminal.com/ru/solana/pools/8Fyshod6nWmaHtifdDnV75v9iGuorNm22rPxMPz6XfMz',
		},
		{
			iconSrc: '/Contacts/link7.svg',
			link: 'https://www.dextools.io/app/en/solana/pair-explorer/8Fyshod6nWmaHtifdDnV75v9iGuorNm22rPxMPz6XfMz?t=1725705220261',
		},
	]

	return (
		<div className={style.contacts}>
			{links.map((link, index) => (
				<Link key={index} iconSrc={link.iconSrc} link={link.link} />
			))}
		</div>
	)
}
