import React from 'react'
import SquareCard from '../../UI/SquareCard/SquareCard'
import style from './HowToBuy.module.scss'

export default function HowToBuy() {
	const cards = [
		{
			number: 1,
			title: '$SOL',
			text: 'your wallet should have $SOL on it, if $SOL is not there, buy it from your wallet or from the dex, cex exchange',
			color: 'yellow',
			animationClass: 'fade-in-left',
		},
		{
			number: 2,
			title: 'SWAP',
			text: 'exchange $SOL for $BULY using popular exchanges. For example you can use Uniswap, go to their website app.uniswap.org and search there for $BULY',
			color: 'green',
			animationClass: 'fade-in-bottom',
		},
		{
			number: 3,
			title: '$BULY',
			text: 'Congratulations, you have become part of the BULLY community, you are now a $BULY holder!',
			color: 'violet',
			animationClass: 'fade-in-right',
		},
	]

	return (
		<div className={style.how_to_buy}>
			<h1 className={style.title}>how to buy</h1>
			<h1 className={style.dashed_title}>$buly</h1>
			<div className={style.cards}>
				{cards.map((card, index) => (
					<SquareCard
						key={index}
						number={card.number}
						title={card.title}
						text={card.text}
						color={card.color}
						animationClass={card.animationClass}
					/>
				))}
			</div>
		</div>
	)
}
