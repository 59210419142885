import React, { useEffect, useRef, useState } from 'react'
import style from './SquareCard.module.scss'

export default function SquareCard({
	number,
	title,
	text,
	color,
	animationClass,
}) {
	const cardRef = useRef(null)
	const [isVisible, setIsVisible] = useState(false)
	useEffect(() => {
		const observer = new IntersectionObserver(
			entries => {
				const entry = entries[0]
				if (entry.isIntersecting) {
					setIsVisible(true)
					observer.unobserve(entry.target) // Отключаем наблюдение после появления элемента
				}
			},
			{ threshold: 0.5 } // Порог видимости элемента
		)

		if (cardRef.current) {
			observer.observe(cardRef.current)
		}

		return () => {
			if (cardRef.current) {
				observer.unobserve(cardRef.current)
			}
		}
	}, [])

	return (
		<div
			ref={cardRef}
			className={`${style.square} ${style[color]} ${
				isVisible ? style[animationClass] : ''
			}`}
		>
			<div className={style.number}>{number}</div>
			<h1 className={style.card_title}>{title}</h1>
			<p className={style.card_text}>{text}</p>
		</div>
	)
}
