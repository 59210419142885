import React from 'react'
import bully_footer from '../../assets/pictures/bully-footer.svg'
import Logo from '../Header/Logo'
import Button from '../UI/Button/Button'
import Contacts from '../UI/Contacts/Contacts'
import style from './Footer.module.scss'
export default function Footer() {
	const pushHref = () =>
		(window.location.href =
			'https://birdeye.so/token/HEMuxZtYy86LcgaRWULnWPf4KpJ1B9r9R9CANR41pump?chain=solana')
	return (
		<div className={style.footer}>
			<section className={style.info}>
				<Logo />
				<h3>
					$BULY is Memcoin on solanA. The best mascot on the current market!
				</h3>
				<Button color='green' text='Buy $Bully' callback={pushHref} />
			</section>
			<section className={style.picture}>
				<img className={style.bully_small} src={bully_footer} alt='Bully' />
			</section>
			<section className={style.contacts}>
				<h2>Bully Bull Socials</h2>
				<Contacts />
				<h3>copyright © 2024. all rights reserved</h3>
			</section>
		</div>
	)
}
