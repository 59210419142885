import React from 'react'
import logo from '../../assets/pictures/logo.svg'
import style from './Logo.module.scss'
export default function Logo() {
	return (
		<div className={style.logo}>
			<img src={logo} alt='logo' />
			<h1>Bully Bull</h1>
		</div>
	)
}
