import React, { useState } from 'react'
import style from './Contract.module.scss'

export default function Contract({ CA }) {
	const [buttonText, setButtonText] = useState('copy')

	const handleCopy = () => {
		navigator.clipboard.writeText(CA)
		setButtonText('copied!')

		// Revert the button text back to 'copy' after 2 seconds
		setTimeout(() => {
			setButtonText('copy')
		}, 2000)
	}

	return (
		<div className={style.contract}>
			<p>CA: {CA}</p>
			<button className={style.copy} onClick={handleCopy}>
				<p>{buttonText}</p>
			</button>
		</div>
	)
}
