import React, { useEffect, useRef, useState } from 'react'
import style from './RectangleCard.module.scss'

export default function RectangleCard({ color, title, text, animationClass }) {
	const cardRef = useRef(null)
	const [isVisible, setIsVisible] = useState(false)
	useEffect(() => {
		const observer = new IntersectionObserver(
			entries => {
				const entry = entries[0]
				if (entry.isIntersecting) {
					setIsVisible(true)
					observer.unobserve(entry.target)
				}
			},
			{ threshold: 0.5 }
		)

		if (cardRef.current) {
			observer.observe(cardRef.current)
		}

		return () => {
			if (cardRef.current) {
				observer.unobserve(cardRef.current)
			}
		}
	}, [])

	return (
		<div
			ref={cardRef}
			className={`${style.rectangle} ${style[color]} ${
				isVisible ? style[animationClass] : ''
			}`}
		>
			<h1 className={style.card_title}>{title}</h1>
			<p className={style.card_text}>{text}</p>
		</div>
	)
}
