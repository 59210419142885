import React, { useEffect, useRef, useState } from 'react'
import style from './Tokenomics.module.scss'
export default function Stat({ color, text }) {
	const statRef = useRef(null)
	const [isVisible, setIsVisible] = useState(false)
	useEffect(() => {
		const observer = new IntersectionObserver(
			entries => {
				const entry = entries[0]
				if (entry.isIntersecting) {
					setIsVisible(true)
					observer.unobserve(entry.target)
				}
			},
			{ threshold: 0.5 }
		)

		if (statRef.current) {
			observer.observe(statRef.current)
		}

		return () => {
			if (statRef.current) {
				observer.unobserve(statRef.current)
			}
		}
	}, [])
	return (
		<div
			ref={statRef}
			className={`${style.stat} ${style[color]} ${
				isVisible ? 'fade-in-left' : ''
			}`}
		>
			{text}
		</div>
	)
}
